/*
 * Ryan O'Dowd
 * 2021-09-26
 *
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

html {
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
